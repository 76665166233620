@import "../variables";

.sm-page-widget-social-links .sm-fonticon {
    text-align: center;
}

/**
 * Social Links - branded (see brandcolors.net)
 */
.sm-page-widget-social-links-blogger .sm-official .sm-fonticon {
    color: #fc4f08;
}

.sm-page-widget-social-links-fiveHundredPX .sm-official .sm-fonticon {
    color: #0099e5;
}

.sm-page-widget-social-links-flickr .sm-official .sm-fonticon {
    color: #ff0084;
}

.sm-page-widget-social-links-instagram .sm-official .sm-fonticon {
    color: #30618a;
}

.sm-page-widget-social-links-facebook .sm-official .sm-fonticon {
    color: #3c5a98;
}

.sm-page-widget-social-links-pinterest .sm-official .sm-fonticon {
    color: #c8232c;
}

.sm-page-widget-social-links-tumblr .sm-official .sm-fonticon {
    color: #34526f;
}

.sm-page-widget-social-links-twitter .sm-official .sm-fonticon {
    color: #000;
}

.sm-page-widget-social-links-vimeo .sm-official .sm-fonticon {
    color: #86c9ef;
}

.sm-page-widget-social-links-wordpress .sm-official .sm-fonticon {
    color: #21759b;
}

.sm-page-widget-social-links-youTube .sm-official .sm-fonticon {
    color: #c4302b;
}

.sm-page-widget-social-links-linkedIn .sm-official .sm-fonticon {
    color: #0e76a8;
}

/**
 * Social Links Sizes
 */
.sm-page-widget-social-links.sm-social-links-small .sm-fonticon {
    width: 24px;
    font-size: 24px;
}

.sm-page-widget-social-links.sm-social-links-medium .sm-fonticon {
    width: 36px;
    font-size: 36px;
}

.sm-page-widget-social-links.sm-social-links-large .sm-fonticon {
    width: 48px;
    font-size: 48px;
}

/**
 * Social Links Spacing
 */
.sm-page-widget-social-links.sm-social-links-small {
    line-height: 24px;
}

.sm-page-widget-social-links.sm-social-links-small[data-layout="row"] li {
    padding-right: 12px;
}

.sm-page-widget-social-links.sm-social-links-medium {
    line-height: 36px;
}

.sm-page-widget-social-links.sm-social-links-medium[data-layout="row"] li {
    padding-right: 18px;
}

.sm-page-widget-social-links.sm-social-links-large {
    line-height: 48px;
}

.sm-page-widget-social-links.sm-social-links-large[data-layout="row"] li {
    padding-right: 24px;
}

.sm-page-widget-social-links.sm-flex-row li:last-child {
    padding-right: 0;
}

.sm-page-widget-social-links {
    align-items: center;
    justify-content: center;
}

// On small viewports always center the social links as all content blocks are
// stacked vertically on small viewports and left or right aligned content looks
// awkward.
@media screen and (min-width: @medium-min-width) {
    .sm-page-widget-social-links[data-align="left"] {
        justify-content: flex-start;
    }

    .sm-page-widget-social-links[data-align="right"] {
        justify-content: flex-end;
    }

    .sm-page-widget-social-links[data-layout="column"][data-align="left"] {
        align-items: flex-start;
    }

    .sm-page-widget-social-links[data-layout="column"][data-align="right"] {
        align-items: flex-end;
    }
}
